export const GA4_TRACKING_ID = "G-LKLMZ8CNB2";
export const GA_TRACKING_ID = "UA-87727037-8";
export const GTM_TRACKING_ID = "GTM-NCKZFJ";

function getFbpCookie() { 
  try {    
     return /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie)[1];  
  } catch (e) {
     return undefined;  
  }
}

export function googleAnalytics(event, type, payload) {
  try {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      //@ts-ignore
      const urlParams = new URLSearchParams(window.location.search);
      payload['fbclid'] = urlParams.get('fbclid');
      payload['fbp'] = getFbpCookie();
      gtag(event, type, payload);
    } else {
      console.log("[google-analytics]: event: ", event, type, payload);
    }
  } catch (err) {
    console.log("[google-analytics]: error", err);
  }
}
