import { es } from "date-fns/locale";
import { TOTE } from "../Drawer";

function pushToteToEnd(items) {
    const hasToteIndex = items.findIndex(item => item.variantId === TOTE.id);
    if (hasToteIndex > -1) {
      const tote = items.splice(hasToteIndex, 1);
      items.push(tote[0]);
    }
    return items;
}

export function addItem(lineItems, item, quantity = 1, specialNotes) {
  let found = false;

  // Get current items
  let updatedItems = [];
  updatedItems = lineItems.map(({ node }) => {
    let { quantity: currentQuantity } = node;

    const { quantityAvailable } = node.variant;

    if (!found && node.variant.id === item.id) {
      // Update the current item in the checkout
      found = true;
      currentQuantity += quantity;
    }

    return {
      variantId: node.variant.id,
      quantity: currentQuantity,
      quantityAvailable,
      customAttributes: node.customAttributes,
    };
  });

  if (!found) {
    // Add the item to the checkout
    let itemToAdd = {
      variantId: item.id,
      quantity,
      quantityAvailable: item.quantityAvailable,
    };
    if (specialNotes) {
      itemToAdd = {
        ...itemToAdd,
        customAttributes: [
          {
            key: "notes",
            value: specialNotes,
          },
        ],
      };
    }

    updatedItems.push(itemToAdd);

  }
  return pushToteToEnd(updatedItems);
}
