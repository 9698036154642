import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { Image } from "./Image";
import { Colour } from "./Colour";
import { Size } from "./Size";
import { Quantity } from "./Quantity";
import { Price } from "./Price";
import { FinalSale } from "./FinalSale";
import { Linkable } from "./Linkable";
import { formatTitle } from "@lib/formatString";
import { googleAnalytics } from "@lib/gtag";
import formatVariantPrice from "../../Product/lib/format-variant-price";
import { useCurrencyState } from "@hooks/useCurrency";
import { getProductURL } from "@services/product";
import { resolveSpecialNotes } from "./lib/resolve-special-notes";
import styles from "./styles/lineitem.module.css";
import { TOTE } from "../Drawer";

export function LineItem({ item, onItemUpdate, loading, handleCloseDrawer }) {
  const { t } = useTranslation("cart");
  const { currency } = useCurrencyState();
  const [quantity, setQuantity] = useState(item.quantity);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item]);

  const { variant, title, customAttributes } = item;
  const { id, image } = variant;
  const finalSale = variant.product.tags.indexOf("final-sale") > -1;
  const { price, compareAtPrice, amount, discount } = formatVariantPrice(
    variant,
    currency,
    item.quantity,
    item.discountAllocations
  );

  useEffect(() => {
    const fetchQuery = async () => {
      const product = await getProductURL(variant.product.handle, variant);
      if (product) {
        setQuery(product.url);
      }
    };

    fetchQuery();
  }, []);

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  const updateItem = (data) => {
    if (data.quantity === 0) {
      googleAnalytics("event", "remove_from_cart", {
        ecommerce: {
          items: [
            {
              item_id: variant.id,
              item_name: item.title,
              price: amount,
              currency,
            },
          ],
        },
      });
    }
    onItemUpdate({ itemId: item.id, variantId: id, ...data });
  };

  const handleQuantity = (e) => {
    const val = Number(e.target.value);
    const max = Number.isInteger(val) ? Number(e.target.max) : 100;
    if (Number.isInteger(val) && val === 0) {
      googleAnalytics("event", "remove_from_cart", {
        ecommerce: {
          items: [
            {
              item_id: variant.id,
              item_name: item.title,
              price: amount,
              currency,
            },
          ],
        },
      });
    }

    if (Number.isInteger(val) && val >= 0 && val <= max) {
      setQuantity(e.target.value);
    }
  };

  const handleBlur = () => {
    const val = Number(quantity);

    if (val !== item.quantity) {
      updateItem({ quantity: val });
    }
  };

  const increaseQuantity = (n = 1) => {
    const val = Number(quantity) + n;

    if (Number.isInteger(val) && val >= 0) {
      setQuantity(val);
      updateItem({ quantity: val });
    }
  };

  const hideToteElements = item.title === TOTE.title;

  return (
    <article className={styles.container}>
      <Linkable query={query} handleCloseDrawer={handleCloseDrawer}>
        <Image image={image} title={title} />
      </Linkable>
      <div className={styles.content}>
        <Linkable query={query} handleCloseDrawer={handleCloseDrawer}>
          <h4>{formatTitle(title)}</h4>
        </Linkable>
        <ul className={styles.list}>
          <Colour variant={variant} />
          <Size variant={variant} />
          <Quantity
            value={quantity}
            max={variant.quantityAvailable}
            disabled={loading}
            onChange={handleQuantity}
            onIncrease={increaseQuantity}
            onBlur={handleBlur}
            hide={hideToteElements}
          />
          <Price price={price} compareAtPrice={compareAtPrice} />
        </ul>
        <FinalSale finalSale={finalSale} discount={discount} />
        {resolveSpecialNotes(customAttributes)}
        {!hideToteElements && (
          <button onClick={() => updateItem({ quantity: 0 })} className={styles.button}>
            {t("remove-item")}
          </button>
        )}
      </div>
    </article>
  );
}

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  onItemUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
